import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import firebase from 'firebase/app';
import 'firebase/database';

const PoUsers = (props) => {
    const [data, setData] = useState([]);
    const [cars, setCars] = useState({});
    const [POMembers, setPOMembers] = useState({});
    const usersdata = useSelector(state => state.usersdata);
    const cartypes = useSelector(state => state.cartypes);
    const dispatch = useDispatch();

    useEffect(() => {

        const drivers = firebase.database().ref().child("users");
        drivers.once('value').then((snapshot) => {

            let values = [];
            snapshot.forEach((child) => {
                values.push(child.val());
            });
            if (values) {
                const check = values.filter((item) => item.PO == props.isPonumber)
                if (check.length > 0) {
                    setData(check)
                } else {
                    setData([])
                }
            }

        });

    }, []);


    const columns = [
        { title: languageJson.first_name, field: 'firstName', editable: 'never' },
        { title: languageJson.last_name, field: 'lastName', editable: 'never' },
        { title: languageJson.email, field: 'email', editable: 'never' },
        { title: languageJson.mobile, field: 'mobile', editable: 'never' },
        // { title: languageJson.user_type, field: 'usertype', editable: 'never' },
        { title: languageJson.profile_image, field: 'profile_image', render: rowData => rowData.profile_image ? <img alt='Profile' src={rowData.profile_image} style={{ width: 50, borderRadius: '50%' }} /> : null, editable: 'never' },
        { title: languageJson.signup_via_refferal, field: 'signupViaReferral', type: 'boolean', editable: 'never' },
        { title: languageJson.refferal_id, field: 'refferalId', editable: 'never' },
    ];

    return (
        usersdata.loading ? <CircularLoading /> :
            <MaterialTable
                title={'Users'}
                columns={columns}
                data={data}
                options={{
                    exportButton: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
                    rowStyle: (data, index) => index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
                    headerStyle: { background: "#242424", color: "#ffffff" }
                }}
            />
    );
}

export default PoUsers;
