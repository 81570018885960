import { citiesRef } from "../config/firebase";
import {
    FETCH_CITY,
    FETCH_CITY_SUCCESS,
    FETCH_CITY_FAILED,
    EDIT_CITY
} from "./types";

export const fetchCities = () => dispatch => {
    dispatch({
        type: FETCH_CITY,
        payload: null
    });
    citiesRef.on("value", snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_CITY_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_CITY_FAILED,
                payload: "No Cities Available."
            });
        }
    });
};

export const editCities = (cities, method) => dispatch => {
    dispatch({
        type: EDIT_CITY,
        payload: method
    });
    citiesRef.set(cities);
}