import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { GoogleComponent } from "react-google-location";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import SearchLocationInput from "./SearchLocationInput";
import SearchLocation from "./SearchLocation";
import SearchLocationTo from "./SearchLocationTo";
import firebase from "firebase/app";
import "firebase/database";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { Dropdown, Selection } from "react-dropdown-now";
import "../../node_modules/react-dropdown-now/style.css";
import Switch from "react-switch";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../assets/logo1.png";
import AlertDialog from "../components/AlertDialog";
import { CalendarToday } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { fetchRoutes } from "../actions/routesactions";
// import {
//     signIn,
//     clearLoginError
// } from "../actions/authactions";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#ffffff",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192,
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: 50,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    borderColor: "#ffffff",
    borderWidth: 1,
    backgroundColor: "#ffffff",
  },
}));

const PoCreateBooking = (props) => {
  const [data, setData] = useState([]);
  const [CarData, setCarData] = useState([]);
  const [UsersData, setUsersData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [FlightDetail, setFlightDetail] = useState("");
  const [PickupSignage, setPickupSignage] = useState("");
  const [Special, setSpecial] = useState("");
  const [Someone, setSomeone] = useState("");
  const [Refference, setRefference] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checkAlert, setAlert] = useState(false);
  const [checkMessage, setCheckMessage] = useState("");
  const [place, setPlace] = useState(null);
  const [whereFrom, setWhereFrom] = useState("");
  const [whereFromLat, setWhereFromLat] = useState("");
  const [whereFromLng, setWhereFromLng] = useState("");
  const [whereTo, setWhereTo] = useState("");
  const [WhereToLat, setWhereToLat] = useState("");
  const [WhereToLng, setWhereToLng] = useState("");
  const [Amount, setAmount] = useState();
  const [value, onChange] = useState(new Date());
  const [valueChildSafety, onChangeChild] = useState(false);
  const [valueAC, onChangeAC] = useState(false);
  const [valuePets, onChangePets] = useState(false);
  const [SelectedCarType, setSelectedCarType] = useState("");
  const [SelectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    getCarData();
    getUsersData();
  }, []);

  const routedata = useSelector((state) => state.Routesdata);
  useEffect(() => {
    dispatch(fetchRoutes());
  }, []);

  // console.log("all Routes Data in PO   ====>>", routedata.routes);

  const fareCalculation = (value) => {
    // console.log('drop down value',value);
    let fare;
    for (let i = 0; i < routedata.routes?.length; i++) {
      if (
        routedata.routes[i].pickup === whereFrom &&
        routedata.routes[i].dropoff === whereTo &&
        routedata.routes[i].vehicleType === value?.label
      ) {
        fare = routedata.routes[i].fare;
        break;
      }
    }
    // console.log("all data of pkg", fare);
    setAmount(fare);
  };
  //  console.log("fare data   ====>>", Amount);
  const getCarData = async () => {
    const drivers = firebase.database().ref().child("rates/car_type");
    drivers.once("value").then((snapshot) => {
      let values = [];
      snapshot.forEach((child) => {
        values.push({
          value: child.val().image,
          label: child.val().name,
        });
      });

      if (values) {
        setCarData(values);
      }
    });
  };
  const getUsersData = async () => {
    const drivers = firebase.database().ref().child("users");
    drivers.once("value").then((snapshot) => {
      let values = [];
      snapshot.forEach((child) => {
        if (child.val().PO) {
          values.push({
            value: child.key,
            label: child.val().firstName + " " + child.val().lastName,
            po: child.val().PO,
          });
        }
      });

      if (values) {
        const check = values.filter((item) => item.po == props.isPonumber);
        if (check.length > 0) {
          setUsersData(check);
        } else {
          setUsersData([]);
        }
      }
    });
  };

  const CreateBooking = async (distance) => {
    const OTP = Math.floor(Math.random() * 90000) + 10000;

    if (whereFrom == "") {
      setCheckMessage("Please Select Where From Address");
      setAlert(true);
    } else if (whereTo == "") {
      setCheckMessage("Please Select Where To Address");
      setAlert(true);
    } else if (SelectedCarType == "") {
      setCheckMessage("Please Select Your Car Type");
      setAlert(true);
    } else if (SelectedUser == "") {
      setCheckMessage("Please Select User");
      setAlert(true);
    } else if (Amount == "") {
      setCheckMessage("Please Enter Amount");
      setAlert(true);
    } else if (FlightDetail == "") {
      setCheckMessage("Please Enter Flight Details");
      setAlert(true);
    } else if (PickupSignage == "") {
      setCheckMessage("Please Enter Pickup Signage");
      setAlert(true);
    } else if (Special == "") {
      setCheckMessage("Please Enter Special Instructions");
      setAlert(true);
    } else {
      var data = {
        PO: props.isPonumber,
        carImage: SelectedCarType.value,
        carType: SelectedCarType.label,
        childsafety: valueChildSafety,
        customer: SelectedUser.value,
        customer_name: SelectedUser.label,
        distance: distance,
        driver: "",
        driver_image: "",
        driver_name: "",
        drop: {
          add: whereTo,
          lat: WhereToLat,
          lng: WhereToLng,
        },
        dropAddress: whereTo,
        estimate: Amount,
        estimateDistance: distance,
        flightdetail: FlightDetail,
        otp: OTP,
        pickup: {
          add: whereFrom,
          lat: whereFromLat,
          lng: whereFromLng,
        },
        pickupAddress: whereFrom,
        pickupsign: PickupSignage,
        refferencecode: Refference,
        serviceType: "pickUp",
        someoneelse: Someone,
        specialinstructions: Special,
        status: "NEW",
        switchAC: valueAC,
        switchTransportation: valuePets,
        total_trip_time: 0,
        trip_cost: 0,
        trip_end_time: "00:00",
        trip_start_time: "00:00",
        tripdate: moment(value).format("LLL"),
      };

      var MyBooking = {
        PO: props.isPonumber,
        carImage: SelectedCarType.value,
        carType: SelectedCarType.label,
        childsafety: valueChildSafety,
        coords: {},
        driver: "",
        driver_image: "",
        driver_name: "",
        drop: {
          add: whereTo,
          lat: WhereToLat,
          lng: WhereToLng,
        },
        dropAddress: whereTo,
        estimate: Amount,
        estimateDistance: distance,
        flightdetail: FlightDetail,
        otp: OTP,
        pickup: {
          add: whereFrom,
          lat: whereFromLat,
          lng: whereFromLng,
        },
        pickupAddress: whereFrom,
        pickupsign: PickupSignage,
        refferencecode: Refference,
        serviceType: "pickUp",
        someoneelse: Someone,
        status: "NEW",
        switchAC: valueAC,
        switchTransportation: valuePets,
        total_trip_time: 0,
        trip_cost: 0,
        trip_end_time: "00:00",
        trip_start_time: "00:00",
        tripdate: moment(value).format("LLL"),
      };

      if (data) {
        firebase
          .database()
          .ref("bookings/")
          .push(data)
          .then((res) => {
            var bookingKey = res.key;
            firebase
              .database()
              .ref(
                "users/" +
                  SelectedUser.value +
                  "/my-booking/" +
                  bookingKey +
                  "/"
              )
              .set(MyBooking)
              .then((res) => {
                setCheckMessage("Your booking has been confrim successfully.!");
                setAlert(true);
                setTimeout(() => {
                  window.location.reload(false);
                }, 2000);
              });
          });
      }
    }
  };

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleFlightDetail = (e) => {
    setFlightDetail(e.target.value);
  };
  const handlePickupSignage = (e) => {
    setPickupSignage(e.target.value);
  };
  const handleSpecial = (e) => {
    setSpecial(e.target.value);
  };
  const handleSomeone = (e) => {
    setSomeone(e.target.value);
  };
  const handleRefference = (e) => {
    setRefference(e.target.value);
  };

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    CreateBooking(100);
    return;
    const DateTime = moment(value).format("LLL");

    let startLoc = '"' + whereFromLat + ", " + whereFromLng + '"';
    let destLoc = '"' + WhereToLat + ", " + WhereToLng + '"';

    var proxyUrl = "https://cors-anywhere.herokuapp.com/",
      targetUrl = `https://maps.googleapis.com/maps/api/directions/json?origin=${startLoc}&destination=${destLoc}&key=AIzaSyAoUx0-A5737n-o7V-UjbwTRuGXFw9dPdw`;
    fetch(proxyUrl + targetUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == "OK") {
          CreateBooking(responseJson.routes[0].legs[0].distance.value);
        } else {
          CreateBooking(0);
        }
        // responseJson.routes[0].legs[0].distance.value
      })
      .catch((error) => {
        CreateBooking(100);
        console.error("Error:", error);
      });
  };

  const handleClose = () => {
    setUsername("");
    setPassword("");
    // dispatch(clearLoginError());
  };

  const handleCallback = (childData) => {
    setWhereFromLat(childData.lat);
    setWhereFromLng(childData.lng);
  };
  const handleCallBackAddress = (childData) => {
    setWhereFrom(childData);
  };

  const handleCallbackTo = (childData) => {
    setWhereToLat(childData.lat);
    setWhereToLng(childData.lng);
  };
  const handleCallBackAddressTo = (childData) => {
    setWhereTo(childData);
  };

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography component="h1" variant="h5" style={{ color: "#232323" }}>
          Create Booking
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <SearchLocation
                Address={handleCallBackAddress}
                parentCallback={handleCallback}
              />
            </div>
            {whereFrom == "" ? null : (
              <div style={{ marginBottom: 20 }}>
                <span style={{ fontWeight: "500" }}>
                  Where From Selected: {whereFrom}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <SearchLocationTo
                Address={handleCallBackAddressTo}
                parentCallback={handleCallbackTo}
                handlematch={() => fareCalculation()}
              />
            </div>

            {whereTo == "" ? null : (
              <div style={{ marginBottom: 20 }}>
                <span style={{ fontWeight: "500" }}>
                  Where To Selected: {whereTo}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginBottom: 0 }}>
              <h4>Please Select Booking Date & Time</h4>
              <DateTimePicker
                format={"y-MM-dd h:mm:ss a"}
                style={{ width: "100%" }}
                onChange={onChange}
                value={value}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: "flex" }}>
              <div style={{ margin: 10, marginRight: 20 }}>
                <h5>Child safety seat</h5>
                <Switch onChange={onChangeChild} checked={valueChildSafety} />
              </div>
              <div style={{ margin: 10, marginRight: 20 }}>
                <h5>AC on/off</h5>
                <Switch onChange={onChangeAC} checked={valueAC} />
              </div>
              <div style={{ margin: 10, marginRight: 20 }}>
                <h5>Transportation of pets</h5>
                <Switch onChange={onChangePets} checked={valuePets} />
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Dropdown
                placeholder="Please Select Car Type"
                className="my-className"
                options={CarData}
                value="one"
                onChange={(value) => {setSelectedCarType(value)}}
                onSelect={(value) => {
                  setSelectedCarType(value);
                  fareCalculation(value);
                }} // always fires once a selection happens even if there is no change
                onClose={(closedBySelection) => {
                  // console.log("closedBySelection?:", closedBySelection);
                }}
                onOpen={() => console.log("open!")}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Dropdown
                placeholder="Please Select User"
                className="my-className"
                options={UsersData}
                value="one"
                onChange={(value) => setSelectedUser(value)}
                onSelect={(value) => setSelectedUser(value)} // always fires once a selection happens even if there is no change
                onClose={(closedBySelection) =>
                  console.log("closedBySelection?:", closedBySelection)
                }
                onOpen={() => console.log("open!")}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="amount"
              // label={"Amount"}
              type="number"
              name="amount"
              autoComplete="amount"
              onChange={handleAmount}
              value={Amount}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="flightDetails"
              label={"Flight Details"}
              name="flightDetails"
              autoComplete="flightDetails"
              onChange={handleFlightDetail}
              value={FlightDetail}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="pickupsignage"
              label={"Pickup Signage"}
              name="pickupsignage"
              autoComplete="pickupsignage"
              onChange={handlePickupSignage}
              value={PickupSignage}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="special"
              label={"Special Instructions"}
              name="special"
              autoComplete="special"
              onChange={handleSpecial}
              value={Special}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="someone"
              label={"Booking for someone else"}
              name="someone"
              autoComplete="someone"
              onChange={handleSomeone}
              value={Someone}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="refference"
              label={"Reference Code"}
              name="refference"
              autoComplete="refference"
              onChange={handleRefference}
              value={Refference}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>

        <Button
          // type="submit"
          onClick={() => handleSubmit()}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Confirm
        </Button>
      </form>
      <AlertDialog open={checkAlert} onClose={() => setAlert(false)}>
        {checkMessage}
      </AlertDialog>
    </div>
  );
};

export default PoCreateBooking;
