import React, { useState, useEffect } from 'react';


import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useLocation } from "react-router-dom";
import {  useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import PoUsers from './PoUsers'
import PoBookings from './PoBookings'
import PoCreateBooking from './PoCreateBooking'
import { fetchRoutes } from "../actions/routesactions";

const PoDashboard = (props) => {
    const location = useLocation();
    const [Check, setCheck] = useState(false);
    const [Active, setActive] = useState(1);
    const usersdata = useSelector(state => state.usersdata);
    const bookingdata = useSelector(state => state.bookingdata);
    const dispatch = useDispatch();
    useEffect(() => {
        if (location.state.detail == '') {
            setCheck(true)
            return
        }

    }, []);

    useEffect(() => {
        dispatch(fetchRoutes());
      }, []);

    const logout = async () => {
        props.history.push({
            pathname: '/'
        })
    }

    return (
        bookingdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#252525' }}>
                    <Button onClick={() => setActive(1)} style={{ backgroundColor: Active == 1 ? '#ffffff' : '#232323', margin: 5, color: Active == 1 ? '#232323' : '#ffffff' }}>Assigned Users</Button>
                    <Button onClick={() => setActive(2)} style={{ backgroundColor: Active == 2 ? '#ffffff' : '#232323', margin: 5, color: Active == 2 ? '#232323' : '#ffffff' }}>Bookings</Button>
                    <Button onClick={() => setActive(3)} style={{ backgroundColor: Active == 3 ? '#ffffff' : '#232323', margin: 5, color: Active == 3 ? '#232323' : '#ffffff' }}>Create Booking</Button>
                    <Button onClick={() => logout()} style={{ backgroundColor: '#232323', margin: 5, color: '#ffffff' }}>LogOut</Button>
                </div>
                {
                    Active == 1 ?
                        <PoUsers isPonumber={location.state.detail} />
                        :
                        Active == 2 ?
                            <PoBookings isPonumber={location.state.detail} />
                            :
                            Active == 3 ?
                                <PoCreateBooking isPonumber={location.state.detail} />
                                : null
                }

            </div >

    )
}

export default PoDashboard;