import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import languageJson from "../config/language";
import firebase from 'firebase/app';
import 'firebase/database';

const PoBookings = (props) => {

  const [data, setData] = useState([]);
  const bookingdata = useSelector(state => state.bookingdata);
  const dispatch = useDispatch();

  useEffect(() => {

    const bookings = firebase.database().ref().child("bookings");
    bookings.once('value').then((snapshot) => {

      let values = [];
      snapshot.forEach((child) => {
        values.push(child.val());
      });
      if (values) {
        const check = values.filter((item) => item.PO == props.isPonumber)

        if (check.length > 0) {

          check.sort(function (a, b) {
            var dateA = new Date(a.tripdate), dateB = new Date(b.tripdate)
            return dateB - dateA
          });

          setData(check)
        } else {
          setData([])
        }
      }

    });

  }, []);

  const columns = [
    { title: 'PO', field: 'PO', editable: 'never' },
    { title: languageJson.booking_date, field: 'tripdate', editable: 'never' },
    { title: languageJson.trip_start_time, field: 'trip_start_time', editable: 'never' },
    { title: languageJson.trip_end_time, field: 'trip_end_time', editable: 'never' },
    { title: languageJson.customer_name, field: 'customer_name', editable: 'never' },
    { title: languageJson.car_type, field: 'carType', editable: 'never' },
    { title: languageJson.vehicle_no, field: 'vehicleReg', editable: 'never' },
    { title: languageJson.assign_driver, field: 'driver_name', editable: 'never' },
    { title: languageJson.pickup_address, field: 'pickupAddress', editable: 'never' },
    { title: languageJson.drop_address, field: 'dropAddress', editable: 'never' },
    { title: "AC", field: 'switchAC', editable: 'never' },
    { title: "Child Safety", field: 'childsafety', editable: 'never' },
    { title: "Pets Transportaion", field: 'switchTransportation', editable: 'never' },
    { title: "Flight Details", field: 'flightdetail', editable: 'never' },
    { title: "Pickup Signage", field: 'pickupsign', editable: 'never' },
    { title: "Special Instructions", field: 'specialinstructions', editable: 'never' },
    { title: "Booking for someone else", field: 'someoneelse', editable: 'never' },
    { title: languageJson.booking_status, field: 'status', editable: 'never' },
    { title: "Estimate Cost", field: 'estimate', editable: 'never' },
    { title: languageJson.Gross_trip_cost, field: 'trip_cost', editable: 'never' },
    { title: languageJson.discount_ammount, field: 'discount', editable: 'never' },
    { title: languageJson.Customer_paid, field: 'customer_paid', editable: 'never' },
    { title: languageJson.payment_status, field: 'payment_status', editable: 'never' },
    { title: languageJson.payment_mode, field: 'payment_mode', editable: 'never' },
    { title: languageJson.payment_getway, field: 'getway', editable: 'never' },
    { title: languageJson.cash_payment_amount, field: 'cashPaymentAmount', editable: 'never' },
    { title: languageJson.card_payment_amount, field: 'cardPaymentAmount', editable: 'never' },
    // { title: languageJson.wallet_payment_amount, field: 'usedWalletMoney', editable: 'never' },
    // { title: languageJson.assign_driver, field: 'driver_name', lookup: Drivers },
    // { title: languageJson.trip_cost_driver_share, field: 'driver_share', editable: 'never' },
    // { title: languageJson.convenience_fee, field: 'convenience_fees', editable: 'never' },

  ];

  return (
    bookingdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={languageJson.booking_text}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
          rowStyle: (data, index) => index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
          headerStyle: { background: "#242424", color: "#ffffff" }
        }}
      // editable={{
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise(resolve => {
      //       setTimeout(() => {
      //         resolve();
      //         dispatch(editBooking(oldData.id, newData));
      //       }, 600);
      //     }),
      // }}
      />
  );
}

export default PoBookings;
