import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { fetchRoutes, editRoutes } from "../actions/routesactions";
import { singleRouteRef } from "../config/firebase";
import { useSelector, useDispatch } from "react-redux";
import languageJson from "../config/language";
import firebase from "firebase/app";
import AlertDialog from "../components/AlertDialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SearchLocation from "./SearchLocation";
import SearchLocationTo from "./SearchLocationTo";
import "firebase/database";
import { Dropdown, Selection } from "react-dropdown-now";
// import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#ffffff",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192,
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: 20,
    // marginLeft:'25%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#242424",
  },
  input: {
    borderColor: "#ffffff",
    borderWidth: 1,
    // height: 50,
    height: 37,
    marginTop: 5,
    paddingBottom: 5,
    backgroundColor: "#ffffff",
  },
}));

export default function Routes() {
  // const navigate = useNavigate();
  // const refreshPage = () => {
  //   navigate(0);
  // }
  const tableRef = React.createRef();
  const addActionRef = React.useRef();
  const classes = useStyles();
  const [CarData, setCarData] = useState([]);
  const [checkAlert, setAlert] = useState(false);
  const [checkMessage, setCheckMessage] = useState("");
  const [SelectedUser, setSelectedUser] = useState("");
  const [whereFrom, setWhereFrom] = useState("");
  const [whereFromLat, setWhereFromLat] = useState("");
  const [whereFromLng, setWhereFromLng] = useState("");
  const [whereTo, setWhereTo] = useState("");
  const [WhereToLat, setWhereToLat] = useState("");
  const [WhereToLng, setWhereToLng] = useState("");
  const [Amount, setAmount] = useState();
  const [number, setNumber] = useState("");
  const [name, setName] = useState({});
  const [Data, setData] = useState([]);
  const dispatch = useDispatch();
  const routedata = useSelector((state) => state.Routesdata);

  useEffect(() => {
    dispatch(fetchRoutes());
    getCarData();
    if (routedata?.routes) {
      setData(routedata.routes);
    }
  }, [routedata?.routes?.length]);

  const columns = [
    // {
    //   title: "Route No.",
    //   field: "routeNo",
    //   //  editable: "never"
    // },
    {
      title: languageJson.vehicle_Name,
      field: "vehicleType",
      // editable: "never",
    },
    {
      title: languageJson.pickup,
      field: "pickup",
      // editable: "never",
    },
    {
      title: languageJson.dropoff,
      field: "dropoff",
      // editable: "never",
    },
    {
      title: languageJson.fare,
      field: "fare",
      // editable: "never"
    },
  ];

  // console.log("all Routes Data ====>>", routedata.routes);
  const getCarData = async () => {
    const drivers = firebase.database().ref().child("rates/car_type");
    drivers.once("value").then((snapshot) => {
      let values = [];
      snapshot.forEach((child) => {
        values.push({
          value: child.val().image,
          label: child.val().name,
        });
      });

      if (values) {
        setCarData(values);
      }
    });
  };

  const CreateRoutes = () => {
    try {
      if (whereFrom == "") {
        setCheckMessage("Please Select Pickup Address");
        setAlert(true);
      } else if (whereTo == "") {
        setCheckMessage("Please Select Dropoff Address");
        setAlert(true);
      } else if (name == "") {
        setCheckMessage("Please Enter Vehicle Name");
        setAlert(true);
      } else if (Amount == "") {
        setCheckMessage("Please Enter Amount");
        setAlert(true);
      } else {
        var data = {
          dropoff: whereTo,
          fare: Amount,
          pickup: whereFrom,
          // routeNo: number,
          vehicleType: name.label,
        };
        if (data) {
          firebase
            .database()
            .ref("routes/")
            .push(data)
            .set(data)
            .then((res) => {
              setCheckMessage("New route has been created successfully.!");
              setAlert(true);
              setTimeout(() => {
                // window.location.href="/Routes";
                // window.location.reload(false);
                window.location.reload(false);
                // window.parent.location = window.parent.location.href
              }, 600);
              // // setTimeout(
              //   "window.open('https://cheemafleetltduk.com/Routes', '_self');",
              //   2000
              // );
              // setTimeout("location.href = 'https://cheemafleetltduk.com/Routes';", 2000);
              // if (window.location.toString() === 'https://cheemafleetltduk.com/Routes') {
              //   setTimeout(function () {
              //     window.location.reload(true);
              //   }, 2000);
              // }
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNumber = (e) => {
    setNumber(e.target.value);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleCallback = (childData) => {
    setWhereFromLat(childData.lat);
    setWhereFromLng(childData.lng);
  };
  const handleCallBackAddress = (childData) => {
    setWhereFrom(childData);
  };

  const handleCallbackTo = (childData) => {
    setWhereToLat(childData.lat);
    setWhereToLng(childData.lng);
  };
  const handleCallBackAddressTo = (childData) => {
    setWhereTo(childData);
  };
  const deleteRoutes = (id) => {
    firebase
      .database()
      .ref("routes/")
      .child(id)
      .remove()
      .then((res) => {
        console.log("response data of new record", res);
        // setCheckMessage("New route has been created successfully.!");
        // setAlert(true);
        setTimeout(() => {
          window.location.reload(false);
          // window.parent.location = window.parent.location.href
          // window.location.href="/Routes"
        }, 600);
        
        // dispatch(fetchRoutes());
      });
  };
  return routedata.loading ? (
    <CircularLoading />
  ) : (
    <>
      <form className={classes.form}>
        <Typography component="h1" variant="h5" style={{ color: "#232323" }}>
          Create New Route
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <SearchLocation
                Address={handleCallBackAddress}
                parentCallback={handleCallback}
              />
            </div>
            {whereFrom == "" ? null : (
              <div style={{ marginBottom: 20 }}>
                <span style={{ fontWeight: "500" }}>
                  Where From Selected: {whereFrom}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <SearchLocationTo
                Address={handleCallBackAddressTo}
                parentCallback={handleCallbackTo}
              />
            </div>

            {whereTo == "" ? null : (
              <div style={{ marginBottom: 20 }}>
                <span style={{ fontWeight: "500" }}>
                  Where To Selected: {whereTo}
                </span>
              </div>
            )}
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="amount"
              label={"Route No"}
              type="number"
              name="amount"
              autoComplete="amount"
              value={number}
              onChange={handleNumber}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid> */}

          <Grid item xs={6}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Dropdown
                placeholder="Vehicle Type"
                className="my-className"
                options={CarData}
                value="one"
                onChange={(value) => setName(value)}
                onSelect={(value) => setName(value)} // always fires once a selection happens even if there is no change
                onClose={(closedBySelection) =>
                  console.log("closedBySelection?:", closedBySelection)
                }
                onOpen={() => console.log("open!")}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="amount"
              label={"Fare"}
              type="number"
              name="amount"
              autoComplete="amount"
              onChange={handleAmount}
              value={Amount}
              autoFocus
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>

        <Button
          // type="submit"
          onClick={() => CreateRoutes()}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Confirm
        </Button>
      </form>
      <AlertDialog open={checkAlert} onClose={() => setAlert(false)}>
        {checkMessage}
      </AlertDialog>
      <MaterialTable
        title={languageJson.routing_text}
        columns={columns}
        data={Data}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
          rowStyle: (data, index) =>
            index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
          headerStyle: { background: "#242424", color: "#ffffff" },
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch(editRoutes(oldData.id, newData));
                // console.log('old data first of all',oldData);
              }, 600);
            }),
          onRowDelete: (oldData) => {
            deleteRoutes(oldData.id);
          },
        }}
      />
    </>
  );
}
