import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';


import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


class SearchLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      latting: null,
      text: 'Where From...'
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ text: address })
        this.props.Address(address)
        this.props.parentCallback(latLng)
      }
      )
      // .then(latLng => this.props.parentCallback(latLng))
      .catch(error => console.error('Error', error));
  };

  render() {
    console.log('Success', this.state.latting)
    return (
      <div >
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                style={{ paddingTop: 18,
                  paddingBottom:18,
                  paddingLeft:15,paddingRight:15, height: 20, width: '100%', borderRadius: 5, borderColor: '#c4c4c4', borderWidth: 1,}}
                {...getInputProps({
                  placeholder: this.state.text,
                  className: 'location-search-input',
                })}
                // onBlur={onBlur}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <div style={{ marginTop: 10 }}>
                        <span style={{ borderBottomColor: '#C4C4C4', borderBottomWidth: 2, paddingBottom: 20, paddingTop: 20 }}>{suggestion.description}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>

    )
  }
}


export default SearchLocation;