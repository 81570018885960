import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo1.png';
import bg from '../assets/bgimage.jpeg';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import languageJson from "../config/language";
import {
  signIn,
  clearLoginError
} from "../actions/authactions";

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
      },
    },
    '& .MuiInputLabel-outlined': {
      color: 'white',
    },
  },
  '@global': {
    body: {
      backgroundColor: '#232323',
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    borderColor: 'black',
    borderWidth: 1,
    color: 'white',
    backgroundColor:'#606060'

  }
}));

const Login = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (auth.info) {
      props.history.push('/');
    }
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signIn(email, password));
  }

  const handleClose = () => {
    setEmail("");
    setPassword("");
    dispatch(clearLoginError());
  };

  return (
    <div style={{ display:'flex',  height:'100vh', backgroundImage:`url(${bg})`, backgroundPosition:'center', backgroundSize:'cover' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
          
        </Avatar> */}
          {/* <img style={{ width: 150, height: 150, marginBottom: 100 }} src={logo} alt="Logo" /> */}
          <Typography component="h1" variant="h5" style={{ color: '#ffffff',marginTop:250 }}>
            {languageJson.sign_in}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>

          <div className={classes.root}>
     
    




            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="text"
              id="email"
              label={languageJson.email_address}
              name="email"
              autoComplete="email"
              onChange={handleEmailChange}
              value={email}
              autoFocus
              disableUnderline={true}
              InputProps={{
                className: classes.input,
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={languageJson.password}
              type="text"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              // autoComplete="current-password"
              disableUnderline={true}
              InputProps={{
                className: classes.input,
              }}
            />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {languageJson.sign_in}
            </Button>
          </form>
        </div>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          style={{ marginTop: 0 }}
          onClick={() => props.history.push('poLogin')}
        >
          PO Login
        </Button>

        <AlertDialog open={auth.error.flag} onClose={handleClose}>{languageJson.sign_in_error}</AlertDialog>
      </Container>
    </div>
  );

}

export default Login;
