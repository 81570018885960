import { routesRef, singleRouteRef } from "../config/firebase";
import "firebase/database";
import {
  FETCH_ROUTES,
  FETCH_ROUTES_SUCCESS,
  FETCH_ROUTES_FAILED,
  EDIT_ROUTES,
  EDIT_ROUTES_SUCCESS,
  EDIT_ROUTES_FAILED,
} from "./types";

export const fetchRoutes = () => (dispatch) => {
  dispatch({
    type: FETCH_ROUTES,
    payload: null,
  });
  routesRef.on("value", (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].dropoff = data[i].dropoff;
        data[i].pickup = data[i].pickup;
        data[i].fare = data[i].fare;
        data[i].vehicleType = data[i].vehicleType;
        data[i].routeNo = data[i].routeNo;
        return data[i];
      });

      // console.log('data from snapshot', arr);
      dispatch({
        type: FETCH_ROUTES_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_ROUTES_FAILED,
        payload: "No Routes available.",
      });
    }
  });
};
export const editRoutes = (id, routes) => (dispatch) => {
  dispatch({
    type: EDIT_ROUTES,
    payload: routes,
  });
  let editedroutes = routes;

  var data = {
    dropoff: editedroutes.dropoff,
    fare: editedroutes.fare,
    pickup: editedroutes.pickup,
    routeNo: editedroutes.routeNo,
    vehicleType: editedroutes.vehicleType,
  };

  delete editedroutes.id;
  singleRouteRef(id)
    .update(data)
    .then(() => {
 
      dispatch({
        type: EDIT_ROUTES_SUCCESS,
        payload: null,
      });
    })
    .catch((error) => {
      dispatch({
        type: EDIT_ROUTES_FAILED,
        payload: error,
      });
    });
};
