import React from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import logo from '../assets/logo1.png';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import languageJson from "../config/language";
import {
  signOut
} from "../actions/authactions";


function AppMenu() {
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  return (
    <div style={{ backgroundColor: '#252525' }}>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#252525' }}>
        <img style={{ marginTop: '50px', marginBottom: '50px', width: '150px', height: '150px' }} src={logo} alt="Logo" />
      </div>
      <Divider />
      <MenuList>
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.dashboard_text}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/customers">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.customers}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/drivers">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.driver}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/admins">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.admins}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/po">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">PO</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/cartypes">
          <ListItemIcon>
            <CarIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.car_type}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <ListIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.booking_history}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/pendingbookings">
          <ListItemIcon>
            <ListIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{'Pending Bookings'}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/Earningreports">
          <ListItemIcon>
            <MoneyIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.earning_reports}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/Routes">
          <ListItemIcon>
            <MoneyIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">Routes</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/promos">
          <ListItemIcon>
            <OfferIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.promo}</Typography>
        </MenuItem>
        {/* <MenuItem component={Link} to="/cities">
          <ListItemIcon>
            <OfferIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.Cities}</Typography>
        </MenuItem> */}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;