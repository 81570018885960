import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import firebase from "firebase/app";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import {
  editUser, deleteUser
} from "../actions/usersactions";

export default function Drivers() {
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usersdata.users) {
      const Users = usersdata.users;
      const Filtered = Users.filter(item => item.usertype == 'driver')
      setData(Filtered);
    }
  }, [usersdata.users]);
// console.log('DRIVER DATA-----',data);
  useEffect(() => {
    // if (cartypes.cars) {
    //   let obj = {};
    //   // console.log(cartypes.cars);
    //   cartypes.cars.map((car) => obj[car.name] = car.name)
    //   setCars(obj);
    // }
    firebase.database().ref().child("rates/car_type").on("value", snapshot => {
      const data = snapshot.val();
      // console.log('gettin values from firebase------', data1);
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].convenience_fees = data[i].convenience_fees;
        data[i].max_business = data[i].max_business;
        data[i].max_passengers = data[i].max_passengers;
        data[i].min_fare = data[i].min_fare;
        data[i].name = data[i].name;
        data[i].rate_per_hour = data[i].rate_per_hour;
        data[i].rate_per_kilometer = data[i].rate_per_kilometer;
        data[i].type = data[i].type;
        data[i].type_description = data[i].type_description;
        return data[i];
      });
      // setCars(arr)
      if (data) {
        let obj = {};
        arr.map((car) => (obj[car.name] = car.name));
        setCars(arr);
      }
    });
  }, [cars?.length]);

  const columns = [
    { title: languageJson.first_name, field: 'firstName', editable: 'never' },
    { title: languageJson.last_name, field: 'lastName', editable: 'never' },
    { title: languageJson.email, field: 'email', editable: 'never' },
    { title: languageJson.mobile, field: 'mobile', editable: 'never' },
    { title: languageJson.profile_image, field: 'driverProfileImage', render: rowData => rowData.driverProfileImage ? <img alt='Profile' src={rowData.driverProfileImage} style={{ width: 50, borderRadius: '50%' }} /> : null, editable: 'never' },
    { title: "Vehicle Type", field: 'vehicleType', editable: 'never' },
    { title: "Vehicle Color", field: 'vehicleColor', editable: 'never' },
    { title: "Vehicle Registration", field: 'vehicleReg', editable: 'never' },
    { title: "Address", field: 'address', editable: 'never' },
    { title: "Company", field: 'company', editable: 'never' },
    { title: "Reference", field: 'refference', editable: 'never' },
    { title: languageJson.account_approve, field: 'approved', type: 'boolean' },
    { title: languageJson.driver_active, field: 'driverActiveStatus', type: 'boolean' },
    // { title: languageJson.wallet_balance, field: 'walletBalance', type: 'numeric', },
    // { title: languageJson.pending_commition, field: 'pendingCommition', type: 'numeric', },
  ];

  const deleteDrivers = (id) => {
    console.log('id for deletion---', id);
    firebase
      .database()
      .ref("users/")
      .child(id)
      .remove()
      .then((res) => {
        console.log("response data of new record", res);
        // setCheckMessage("New route has been created successfully.!");
        // setAlert(true);
        setTimeout(() => {
          window.location.reload(false);
          // window.parent.location = window.parent.location.href
          // window.location.href="/Routes"
        }, 600);
        
        // dispatch(fetchRoutes());
      });
  };

  return (
    usersdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={languageJson.driver}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
          rowStyle: (data, index) => index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
          headerStyle: { background: "#242424", color: "#ffffff" }
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
            onRowDelete: (oldData) => {
              new Promise(resolve =>{
                setTimeout(() => {
                  resolve();
                deleteDrivers(oldData.id);
              }, 600);
              })
            },
        }}
      />
  );
}
