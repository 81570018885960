import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import {
    editCities
} from "../actions/citiesactions";

export default function Po() {
    const columns = [
        { title: "PO Numaber", field: 'po_number', },
        { title: "Companay Name", field: 'company_name', },
        { title: "Username", field: 'username', },
        { title: "Password", field: 'password', },
        { title: "Email", field: 'email', },

    ];
    const [data, setData] = useState([]);
    const cities = useSelector(state => state.cities);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cities.cars) {
            setData(cities.cars);
        }
    }, [cities.cars]);
console.log('po data-------', data);
    const removeExtraKeys = (tblData) => {
        if (tblData.po_number) tblData.po_number = parseFloat(tblData.po_number);
        if (tblData.company_name) tblData.company_name = parseFloat(tblData.company_name);
        return tblData;
    }

    return (
        cities.loading ? <CircularLoading /> :
            <div>
                <MaterialTable
                    title={'PO / AD PO'}
                    columns={columns}
                    data={data}
                    options={{
                        exportButton: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
                        rowStyle: (data, index) => index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
                        headerStyle: { background: "#242424", color: "#ffffff" }
                    }}
                    editable={{

                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.push(newData);
                                    dispatch(editCities(removeExtraKeys(tblData), "Add"));
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    dispatch(editCities(removeExtraKeys(tblData), "Update"));
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editCities(removeExtraKeys(tblData), "Delete"));
                                }, 600);
                            }),
                    }}
                />
            </div>
    );
}

