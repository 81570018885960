import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import Login from './views/Login';
import PoLogin from './views/poLogin';
import Dashboard from './views/Dashboard';
import PoDashboard from './views/PoDashboard';
import CarTypes from './views/CarTypes';
import Bookings from './views/Bookings';
import PendingBookings from './views/PendingBookings';
import Promos from './views/Promos';

import Po from './views/Po';

import Customers from './views/Customers';
import Drivers from './views/Drivers';
import Admins from './views/Admins';
import Referral from './views/Referral';
import { fetchUser } from "./actions/authactions";
import AuthLoading from './components/AuthLoading';
import Notifications from './views/Notifications';
import DriverEarning from './views/DriverEarning'
import Earningreports from './views/Earningreports'
import Routes from './views/Routes';
function App() {

  store.dispatch(fetchUser());

  return (
    <Provider store={store}>
      <AuthLoading>
        <Router>
          <Switch>
            <ProtectedRoute exact component={Dashboard} path="/" />
            <ProtectedRoute exact component={CarTypes} path="/cartypes" />
            <ProtectedRoute exact component={Bookings} path="/bookings" />
            <ProtectedRoute exact component={PendingBookings} path="/pendingbookings" />
            <ProtectedRoute exact component={Promos} path="/promos" />
            <ProtectedRoute exact component={Po} path="/po" />
            <ProtectedRoute exact component={Customers} path="/customers" />
            <ProtectedRoute exact component={Drivers} path="/drivers" />
            <ProtectedRoute exact component={Admins} path="/admins" />
            <ProtectedRoute exact component={DriverEarning} path="/driverearning" />
            <ProtectedRoute exact component={Referral} path="/referral" />
            <ProtectedRoute exact component={Notifications} path="/notifications" />
            <ProtectedRoute exact component={Earningreports} path="/earningreports" />
            <ProtectedRoute exact component={Routes} path="/Routes" />
            <Route exact component={Login} path="/login" />
            <Route exact component={PoLogin} path="/PoLogin" />
            <Route exact component={PoDashboard} path="/PoDashboard" />
          </Switch>
        </Router>
      </AuthLoading>
    </Provider>
  );
}

export default App;
