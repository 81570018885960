import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import {
  editUser, deleteUser
} from "../actions/usersactions";
import firebase from 'firebase/app';
import 'firebase/database';
export default function Admins() {
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usersdata.users) {
      const Users = usersdata.users;
      const Filtered = Users.filter(item => item.usertype === 'Admin')
      setData(Filtered);
    }
  }, [usersdata.users]);

  useEffect(() => {
    // if (cartypes.cars) {
    //   setData(cartypes.cars);
    // }
    firebase.database().ref().child("rates/car_type").on("value", snapshot => {
      const data = snapshot.val();
      // console.log('gettin values from firebase------', data1);
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].convenience_fees = data[i].convenience_fees;
        data[i].max_business = data[i].max_business;
        data[i].max_passengers = data[i].max_passengers;
        data[i].min_fare = data[i].min_fare;
        data[i].name = data[i].name;
        data[i].rate_per_hour = data[i].rate_per_hour;
        data[i].rate_per_kilometer = data[i].rate_per_kilometer;
        data[i].type = data[i].type;
        data[i].type_description = data[i].type_description;
        return data[i];
      });
      setCars(arr)
    });
  }, [cars?.length]);

  const columns = [
    { title: languageJson.first_name, field: 'firstName', },
    { title: languageJson.last_name, field: 'lastName', },
    { title: languageJson.user_type, field: 'usertype', editable: 'never' },
  ];

  return (
    usersdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={'Admins'}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
          rowStyle: (data, index) => index % 2 === 0 ? { backgroundColor: "#EAEAEA" } : null,
          headerStyle: { background: "#242424", color: "#ffffff" }
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),


          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(deleteUser(oldData.id));
              }, 600);
            }),
        }}
      />
  );
}
