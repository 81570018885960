import { bookingRef, singleBookingRef } from "../config/firebase";
import firebase from 'firebase/app';
import 'firebase/database';
import {
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILED,
  EDIT_BOOKINGS_SUCCESS,
  EDIT_BOOKING_FAILED,
  EDIT_BOOKINGS
} from "./types";

export const fetchBookings = () => dispatch => {
  dispatch({
    type: FETCH_BOOKINGS,
    payload: null
  });
  bookingRef.on("value", snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        data[i].pickupAddress = data[i].pickup.add;
        data[i].dropAddress = data[i].drop.add;
        data[i].discount = data[i].discount_amount ? data[i].discount_amount : 0;
        data[i].cashPaymentAmount = data[i].cashPaymentAmount ? data[i].cashPaymentAmount : 0
        data[i].cardPaymentAmount = data[i].cardPaymentAmount ? data[i].cardPaymentAmount : 0
        return data[i]
      });
      console.log('array from user-----', arr);
      dispatch({
        type: FETCH_BOOKINGS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_BOOKINGS_FAILED,
        payload: "No bookings available."
      });
    }
  });
};

export const editBooking = (id, user) => dispatch => {
  dispatch({
    type: EDIT_BOOKINGS,
    payload: user
  });
  let editedUser = user;
  let driverID = editedUser.driver_name;
  let bookingKey = editedUser.id;

  const drivers = firebase.database().ref().child("users");
  drivers.once('value').then((snapshot) => {

    let values = [];
    snapshot.forEach((child) => {
      values.push(child.val());
    });

    const Filtered = values.filter(item => item.usertype === 'driver')
    if (Filtered) {

      if (editedUser.driver_comission === undefined || editedUser.driver_comission === 0 || editedUser.driver_comission === "") {
        alert("Please enter driver comission")
      } else {
        var data = {
          driver_comission: editedUser.driver_comission,
          carImage: editedUser.carImage,
          carType: editedUser.carType,
          customer: editedUser.customer,
          customer_name: editedUser.customer_name,
          distance: editedUser.distance,
          driver: editedUser.driver,
          driver_image: editedUser.driver_image,
          driver_name: "",
          drop: editedUser.drop,
          pickup: editedUser.pickup,
          estimate: editedUser.estimate,
          estimateDistance: editedUser.estimateDistance,
          serviceType: editedUser.serviceType,
          status: editedUser.status,
          total_trip_time: editedUser.total_trip_time,
          trip_cost: editedUser.trip_cost,
          trip_end_time: editedUser.trip_end_time,
          trip_start_time: editedUser.trip_start_time,
          tripdate: editedUser.tripdate,
          childsafety: editedUser.childsafety,
          switchAC: editedUser.switchAC,
          switchTransportation: editedUser.switchTransportation,
          flightdetail: editedUser.flightdetail,
          pickupsign: editedUser.pickupsign,
          specialinstructions: editedUser.specialinstructions,
          someoneelse: editedUser.someoneelse,
          refferencecode: editedUser.refferencecode,
          vehicleReg: Filtered[0].vehicleReg,
          otp: '',
        }

        delete editedUser.id;
        singleBookingRef(id).set(editedUser).then(() => {
          singleBookingRef(id).update({ requestedDriver: { 0: driverID } }).then(() => {
            firebase.database().ref('users/' + driverID + '/waiting_riders_list/' + bookingKey + '/').set(data).then(() => {
              dispatch({
                type: EDIT_BOOKINGS_SUCCESS,
                payload: null
              });
            })
          })
        }).catch((error) => {
          dispatch({
            type: EDIT_BOOKING_FAILED,
            payload: error
          });
        });
      }
    }
  });

}