import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import languageJson from "../config/language";
import { editBooking } from "../actions/bookingactions";
import firebase from "firebase/app";
import "firebase/database";

export default function Bookings() {
  const cartypes = useSelector((state) => state.cartypes);
  const [cars, setCars] = useState({});
  const [Drivers, setDrivers] = useState({});

  useEffect(() => {
    const drivers = firebase.database().ref().child("users");
    drivers.once("value").then((snapshot) => {
      let values = [];
      snapshot.forEach((child) => {
        values.push(child.val());
      });

      const Filtered = values.filter((item) => item.usertype === "driver");
      if (Filtered) {
        let obj = {};
        Filtered.map(
          (driver) =>
            (obj[driver.id] = driver.firstName + " " + driver.lastName)
        );
        // alert(JSON.stringify(values))
        setDrivers(obj);
      }
    });

    // if (cartypes.cars) {
    //   let obj = {};
    //   cartypes.cars.map((car) => (obj[car.name] = car.name));
    //   setCars(obj);
    // }
    firebase.database().ref().child("rates/car_type").on("value", snapshot => {
      const data = snapshot.val();
      // console.log('gettin values from firebase------', data1);
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].convenience_fees = data[i].convenience_fees;
        data[i].max_business = data[i].max_business;
        data[i].max_passengers = data[i].max_passengers;
        data[i].min_fare = data[i].min_fare;
        data[i].name = data[i].name;
        data[i].rate_per_hour = data[i].rate_per_hour;
        data[i].rate_per_kilometer = data[i].rate_per_kilometer;
        data[i].type = data[i].type;
        data[i].type_description = data[i].type_description;
        return data[i];
      });
      // setCars(arr)
      if (data) {
        let obj = {};
        arr.map((car) => (obj[car.name] = car.name));
        setCars(arr);
      }
    });
  }, [cars?.length]);
  const deletebooking = (id) => {
    console.log('id from deletion', id);
    firebase
      .database()
      .ref("bookings/")
      .child(id)
      .remove()
      .then((res) => {
        console.log("response data of new record", res);
        // setCheckMessage("New route has been created successfully.!");
        // setAlert(true);
        setTimeout(() => {
          window.location.reload(false);
          // window.parent.location = window.parent.location.href
          // window.location.href="/Routes"
        }, 600);
        
        // dispatch(fetchRoutes());
      });
  };
  const columns = [
    { title: "PO", field: "PO", editable: "never" },
    { title: languageJson.booking_date, field: "tripdate", 
    // editable: "never"
   },
    {
      title: languageJson.trip_start_time,
      field: "trip_start_time",
      // editable: "never",
    },
    {
      title: languageJson.trip_end_time,
      field: "trip_end_time",
      // editable: "never",
    },
    {
      title: languageJson.customer_name,
      field: "customer_name",
      // editable: "never",
    },
    { title: languageJson.car_type, field: "carType", 
    // editable: "never"
   },
    {
      title: languageJson.pickup_address,
      field: "pickupAddress",
      // editable: "never",
    },
    {
      title: languageJson.drop_address,
      field: "dropAddress",
      // editable: "never",
    },
    {
      title: languageJson.assign_driver,
      field: "driver_name",
      lookup: Drivers,
    },
    { title: "Driver Commission", field: "driver_comission" },
    { title: "AC", field: "switchAC",
    //  editable: "never"
     },
    { title: "Child Safety", field: "childsafety", 
    // editable: "never"
   },
    {
      title: "Pets Transportaion",
      field: "switchTransportation",
      // editable: "never",
    },
    { title: "Flight Details", field: "flightdetail", 
    // editable: "never" 
  },
    { title: "Pickup Signage", field: "pickupsign",
    //  editable: "never"
     },
    {
      title: "Special Instructions",
      field: "specialinstructions",
      // editable: "never",
    },
    {
      title: "Booking for someone else",
      field: "someoneelse",
      // editable: "never",
    },
    { title: languageJson.booking_status, field: "status", 
    // editable: "never"
   },
    { title: "Estimate Cost", field: "estimate", 
    // editable: "never"
   },
    {
      title: languageJson.discount_ammount,
      field: "discount",
      // editable: "never",
    },
    {
      title: languageJson.Customer_paid,
      field: "customer_paid",
      // editable: "never",
    },
    {
      title: languageJson.payment_status,
      field: "payment_status",
      // editable: "never",
    },
    {
      title: languageJson.payment_mode,
      field: "payment_mode",
      // editable: "never",
    },
    { title: languageJson.payment_getway, field: "getway", 
    // editable: "never"
   },
    {
      title: languageJson.cash_payment_amount,
      field: "cashPaymentAmount",
      // editable: "never",
    },
    {
      title: languageJson.card_payment_amount,
      field: "cardPaymentAmount",
      // editable: "never",
    },

    // { title: languageJson.vehicle_no, field: 'vehicle_number', editable: 'never' },
    // { title: languageJson.trip_cost_driver_share, field: 'driver_share', editable: 'never' },
    // { title: languageJson.convenience_fee, field: 'convenience_fees', editable: 'never' },
    // { title: languageJson.Gross_trip_cost, field: 'trip_cost', editable: 'never' },
    // { title: languageJson.wallet_payment_amount, field: 'usedWalletMoney', editable: 'never' },
  ];

  const [data, setData] = useState([]);
  const bookingdata = useSelector((state) => state.bookingdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bookingdata.bookings) {
      const Bookings = bookingdata.bookings.filter(
        (booking) => booking.status === "NEW" || booking.status === "CANCELLED"
      );

      Bookings.sort(function (a, b) {
        var dateA = new Date(a.tripdate),
          dateB = new Date(b.tripdate);
        return dateA - dateB;
      });

      setData(Bookings.reverse());
    }
  }, [bookingdata.bookings]);
  // console.log("all pending data =====>>>", data);

  return bookingdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={"Pending Bookings"}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
        rowStyle: (data, index) =>
          index % 2 === 0 ? { backgroundColor: "#EAEAEA" } : null,
        headerStyle: { background: "#242424", color: "#ffffff" },
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(editBooking(oldData.id, newData));
            }, 600);
          }),
          onRowDelete: (oldData) => {
            deletebooking(oldData.id);
          },
      }}
    />
  );
}
