import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import SearchLocationTo from "./SearchLocationTo";
import TextField from "@material-ui/core/TextField";
import languageJson from "../config/language";
import Grid from "@material-ui/core/Grid";
import firebase from "firebase/app";
import SearchLocation from "./SearchLocation";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AlertDialog from "../components/AlertDialog";
import { Dropdown, Selection } from "react-dropdown-now";
import "firebase/storage";
import 'firebase/database';

// import {
//   ref, uploadBytesResumable, getDownloadURL
// } from "firebase/storage";
import {
  editCarType
} from "../actions/cartypeactions";
import { Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#ffffff",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192,
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: 20,
    // marginLeft:'25%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#242424",
  },
  input: {
    borderColor: "#ffffff",
    borderWidth: 1,
    // height: 50,
    height: 37,
    marginTop: 5,
    paddingBottom: 5,
    backgroundColor: "#ffffff",
  },
}));

export default function CarTypes({ label, ...rest }) {
  const columns = [
    { title: languageJson.image, field: 'image', render: rowData => <img alt='Car' src={rowData.image} style={{ width: 50 }} /> },
    { title: languageJson.name, field: 'name' },
    { title: 'Type', field: 'type' },
    { title: 'Type Description', field: 'type_description' },
    { title: 'Max Passengers', field: 'max_passengers' },
    { title: 'Max Business', field: 'max_business' },
    { title: languageJson.rate_per_km, field: 'rate_per_kilometer' },
    { title: languageJson.rate_per_hour, field: 'rate_per_hour' },
    { title: languageJson.min_fare, field: 'min_fare' },
    { title: languageJson.convenience_fee_percent, field: 'convenience_fees' }
  ];
  const [Data, setData] = useState([]);
  const [CarData, setCarData] = useState([]);
  const [checkMessage, setCheckMessage] = useState("");
  const [checkAlert, setAlert] = useState(false);
  const classes = useStyles();
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [passengers, setPassengers] = useState('');
  const [business, setBusiness] = useState('');
  const [kmRate, setKmRate] = useState('');
  const [HourRate, setHourRate] = useState('');
  const [convenience, setConvenience] = useState('');
  const [mFee, setMfee] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  // const handleUpload = () => {
  //   if (!selectedFile) {
  //     alert("Please choose a image first!")
  //   }

  //   const storageRef = ref(storage, `/files/${selectedFile}`)
  //   const uploadTask = uploadBytesResumable(storageRef, selectedFile);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const percent = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );

  //       // update progress
  //       //             setPercent(percent);
  //       console.log('percent of uploading---', percent);
  //     },
  //     (err) => console.log(err),
  //     () => {
  //       // download url
  //       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //         console.log('url after-', url);
  //       });
  //     }
  //   );
  // }




console.log('data from db-----',Data);



  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    // if (cartypes.cars) {
    //   setData(cartypes.cars);
    // }
    firebase.database().ref().child("rates/car_type").on("value", snapshot => {
      const data = snapshot.val();
      // console.log('gettin values from firebase------', data1);
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].convenience_fees = data[i].convenience_fees;
        data[i].max_business = data[i].max_business;
        data[i].max_passengers = data[i].max_passengers;
        data[i].min_fare = data[i].min_fare;
        data[i].name = data[i].name;
        data[i].rate_per_hour = data[i].rate_per_hour;
        data[i].rate_per_kilometer = data[i].rate_per_kilometer;
        data[i].type = data[i].type;
        data[i].type_description = data[i].type_description;
        return data[i];
      });
      setData(arr)
    });
  }, [Data?.length]);
  console.log('data from db------', Data);
  const removeExtraKeys = (tblData) => {
    for (let i = 0; i < tblData.length; i++) {
      if (tblData[i].rate_per_kilometer) tblData[i].rate_per_kilometer = parseFloat(tblData[i].rate_per_kilometer);
      if (tblData[i].rate_per_hour) tblData[i].rate_per_hour = parseFloat(tblData[i].rate_per_hour);
      if (tblData[i].convenience_fees) tblData[i].convenience_fees = parseFloat(tblData[i].convenience_fees);
    }
    return tblData;
  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);

    fileRef.put(file).then((snapshot) => {
      console.log("File uploaded successfully");
      snapshot.ref.getDownloadURL().then((url) => {
        console.log(url);
        setSelectedFile(url);
        // Set the URL to a state or pass it to a component as props
      });
    });
  }


  const submitItem = (e) => {

    e.preventDefault();


    try {
      if (value == "") {
        setCheckMessage("Please Enter Name");
        setAlert(true);
      } else if (type == "") {
        setCheckMessage("Please Enter Type");
        setAlert(true);
      } else if (description == "") {
        setCheckMessage("Please Enter Description");
        setAlert(true);
      } else if (convenience == "") {
        setCheckMessage("Please Enter Convenience Fees");
        setAlert(true);
      } else if (mFee == "") {
        setCheckMessage("Please Enter minimum Fees");
        setAlert(true);
      } else if (business == "") {
        setCheckMessage("Please Enter Max Business");
        setAlert(true);
      } else if (selectedFile == "") {
        setCheckMessage("Please Select Image");
        setAlert(true);
      } else {
        // const obj = 

        // console.log('uploaded data-------', obj);
        // if (obj) {
          firebase
            .database()
            .ref("rates/car_type")
            .push({
              convenience_fees: convenience,
              max_business: business,
              max_passengers: passengers,
              min_fare: mFee,
              name: value,
              rate_per_hour: HourRate,
              rate_per_kilometer: kmRate,
              type: type,
              type_description: description,
              image: selectedFile,
              tableData: {
                id: Math.random(0, 1)
              }
            })
            .set({
              convenience_fees: convenience,
              max_business: business,
              max_passengers: passengers,
              min_fare: mFee,
              name: value,
              rate_per_hour: HourRate,
              rate_per_kilometer: kmRate,
              type: type,
              type_description: description,
              image: selectedFile,
              tableData: {
                id: Math.random(0, 1)
              }
            })
            .then((res) => {
              console.log('response from upload data---', res);
              setTimeout(() => {
                // window.location.reload(false);
                setIsOpen(false)
              }, 600);

            });
        }
      // }
    } catch (error) {
      console.log(error);
    }

    // // dispatch(editCarType(removeExtraKeys(obj), "Add"));
    // console.log( selectedFile);
    // console.log(mFee);
    // console.log(convenience);
    // console.log(HourRate);
    // console.log(kmRate);
    // console.log(business);
    // console.log(passengers);
    // console.log(description);
    // console.log(value);
    // console.log(type);
  }



  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  return (

    cartypes.loading ? <CircularLoading /> :
      <>
        {isOpen ? (<div></div>) : (
          <button style={{
            width: "120px",
            height: "35px",
            borderRadius: "5px",
            color: "white",
            backgroundColor: "black"
          }} onClick={handleClick}>Add Fleet</button>
        )}
        {isOpen && <div style={{ width: 990, height: '30%', alignSelf: "center", backgroundColor: 'white', boxShadow: '1px 2px 9px #A9A9A9', borderRadius: 10, marginBottom: 5, }}>

          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: "center", height: 90, }}>
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Name'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={value}
              onChange={(value) => setValue(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Type'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={type}
              onChange={(value) => setType(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Type Discription'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={description}
              onChange={(value) => setDescription(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Max Passengers'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={passengers}
              onChange={(value) => setPassengers(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: "center", height: 90, }}>
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Max Business'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={business}
              onChange={(value) => setBusiness(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Rate Per KM'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={kmRate}
              onChange={(value) => setKmRate(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Rate Per Hour'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={HourRate}
              onChange={(value) => setHourRate(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Minimum Fare'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={mFee}
              onChange={(value) => setMfee(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: "center", height: 90, }}>
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              placeholder='Convenience Fees'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              value={convenience}
              onChange={(value) => setConvenience(value.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</label>
            <input
              // type={'file'}
              type="file"
              placeholder='Image'
              style={{
                marginLeft: 10,
                width: 200,
                padding: '10px',
                border: `1px solid ${isFocused ? '#0077ff' : '#ccc'}`,
                borderRadius: '4px',
                fontSize: '16px',
                outline: 'none',
                transition: 'border-color 0.2s ease-in-out',
              }}
              {...rest}
              // value={selectedFile}
              // onChange={(value) => setSelectedFile(value.target.value)}
              onChange={handleFileInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: "center", height: 90, }}>
            <button
              onClick={submitItem}
              style={{
                width: 220,
                height: "35px",
                borderRadius: "5px",
                color: "white",
                backgroundColor: "black",
                marginBottom:"40px"
              }}>Submit</button>

          </div>





        </div>}

        <AlertDialog open={checkAlert} onClose={() => setAlert(false)}>
          {checkMessage}
        </AlertDialog>
        <MaterialTable
          title={languageJson.car_type}
          columns={columns}
          data={Data}
          options={{
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
            rowStyle: (data, index) => index % 2 === 0 ? { backgroundColor: "#EAEAEA" } : null,
            headerStyle: { background: "#242424", color: "#ffffff" }
          }}
          editable={{

            // onRowAdd: newData =>
            //   new Promise(resolve => {
            //     setTimeout(() => {
            //       resolve();
            //       const tblData = data;
            //       tblData.push(newData);
            //       console.log('upload data-----', removeExtraKeys(tblData));
            //       dispatch(editCarType(removeExtraKeys(tblData), "Add"));
            //     }, 600);
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const tblData = Data;
                  tblData[tblData.indexOf(oldData)] = newData;
                  dispatch(editCarType(removeExtraKeys(tblData), "Update"));
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const tblData = Data;
                  tblData.splice(tblData.indexOf(oldData), 1);
                  dispatch(editCarType(removeExtraKeys(tblData), "Delete"));
                }, 600);
              }),

          }}
        />
      </>
  );
}
