export const FirebaseConfig = {
  apiKey: "AIzaSyB0Ssxe4w7Aq8YBwf8JUMZWzzN97BbRmKw",
  authDomain: "cheema-24ac1.firebaseapp.com",
  databaseURL: "https://cheema-24ac1-default-rtdb.firebaseio.com",
  projectId: "cheema-24ac1",
  storageBucket: "cheema-24ac1.appspot.com",
  messagingSenderId: "299799493041",
  appId: "1:299799493041:web:4d7fbd8c8518594fd2e590",
  measurementId: "G-FDJB4N67KV"
  };
