import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import firebase from 'firebase/app';
import 'firebase/database';
import {
  editUser
} from "../actions/usersactions";

export default function Customers() {
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const [POMembers, setPOMembers] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();
// console.log('cars data-----',cartypes);
  useEffect(() => {
    if (usersdata.users) {
      const Users = usersdata.users;
      const Filtered = Users.filter(item => item.usertype == 'rider')
      setData(Filtered);

      const drivers = firebase.database().ref().child("Po");
      drivers.once('value').then((snapshot) => {

        let values = [];
        snapshot.forEach((child) => {
          values.push(child.val());
        });

        if (values) {
          let obj = {};
          values.map((driver) => obj[driver.po_number] = driver.po_number)
          // alert(JSON.stringify(values))
          setPOMembers(obj);
        }

      });

    }
  }, [usersdata.users]);



    // if (cartypes.cars) {
    //   let obj = {};
    //   // console.log(cartypes.cars);
    //   cartypes.cars.map((car) => obj[car.name] = car.name)
    //   setCars(obj);
    // }
    useEffect(() => {
      // if (cartypes.cars) {
      //   setData(cartypes.cars);
      // }
      firebase.database().ref().child("rates/car_type").on("value", snapshot => {
        const data = snapshot.val();
        // console.log('gettin values from firebase------', data1);
        const arr = Object.keys(data).map((i) => {
          data[i].id = i;
          data[i].convenience_fees = data[i].convenience_fees;
          data[i].max_business = data[i].max_business;
          data[i].max_passengers = data[i].max_passengers;
          data[i].min_fare = data[i].min_fare;
          data[i].name = data[i].name;
          data[i].rate_per_hour = data[i].rate_per_hour;
          data[i].rate_per_kilometer = data[i].rate_per_kilometer;
          data[i].type = data[i].type;
          data[i].type_description = data[i].type_description;
          return data[i];
        });
        setCars(arr)
      });
    }, [cars?.length]);
 


  const deleteUsers = (id) => {
    console.log('id for deletion---', id);
    firebase
      .database()
      .ref("users/")
      .child(id)
      .remove()
      .then((res) => {
        console.log("response data of new record", res);
        // setCheckMessage("New route has been created successfully.!");
        // setAlert(true);
        setTimeout(() => {
          window.location.reload(false);
          // window.parent.location = window.parent.location.href
          // window.location.href="/Routes"
        }, 600);
        
        // dispatch(fetchRoutes());
      });
  };

// const addUser =(data)=>{
//   console.log('data for adding----',data);
//   firebase
//   .database()
//   .ref("users/")
//   .push(data)
//   .set(data)
//   .then((res) => {
//     console.log('response from upload data---', res);
//     setTimeout(() => {
//       window.location.reload(false);
//     }, 600);
//   });
// }


  const columns = [
    { title: languageJson.first_name, field: 'firstName',
    //  editable: 'never'
     },
    { title: languageJson.last_name, field: 'lastName',
    //  editable: 'never'
     },
    { title: languageJson.email, field: 'email', 
    // editable: 'never' 
  },
    { title: languageJson.mobile, field: 'mobile',
    //  editable: 'never'
     },
    // { title: languageJson.user_type, field: 'usertype', editable: 'never' },
    { title: languageJson.profile_image, field: 'profile_image', render: rowData => rowData.profile_image ? <img alt='Profile' src={rowData.profile_image} style={{ width: 50, borderRadius: '50%' }} /> : null, editable: 'never' },
    { title: languageJson.signup_via_refferal, field: 'signupViaReferral', type: 'boolean', 
    // editable: 'never' 
  },
    { title: languageJson.refferal_id, field: 'refferalId', 
    // editable: 'never' 
  },
    { title: 'PO', field: 'PO', lookup: POMembers },
    { title: languageJson.account_approve, field: 'approved', type: 'boolean' },
  ];

  return (
    usersdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={languageJson.customers}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 500, 1000],
          rowStyle: (data, index) => index % 2 == 0 ? { backgroundColor: "#EAEAEA" } : null,
          headerStyle: { background: "#242424", color: "#ffffff" }
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editUser(oldData.id, newData));
              }, 600);
            }),
            onRowAdd: newData =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                    const tblData = data;
                    tblData.push(newData);
                    // dispatch(editCities(removeExtraKeys(tblData), "Add"));
                    // console.log('new data from ----', newData);
                    firebase
                    .database()
                    .ref("users/")
                    .push(newData)
                    .set(newData)
                    .then((res) => {
                      console.log('response from upload data---', res);
                    });
                }, 600);
            }),

          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                // dispatch(deleteUser(oldData.id));
                deleteUsers(oldData.id);
              }, 600);
            }),
        }}
      />
  );
}
