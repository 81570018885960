import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../assets/logo1.png";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import languageJson from "../config/language";
import firebase from "firebase/app";
import "firebase/database";
import bg from '../assets/bgimage.jpeg';
import { signIn, clearLoginError } from "../actions/authactions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
  },
  "@global": {
    body: {
      backgroundColor: "#232323",
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    borderColor: "black",
    borderWidth: 1,
    color: "white",
    backgroundColor: "#606060",
  },
}));

const PoLogin = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checkAlert, setAlert] = useState(false);

  useEffect(() => {
    if (auth.info) {
      props.history.push("/");
    }
  });

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    const drivers = firebase.database().ref().child("Po");
    drivers.once("value").then((snapshot) => {
      let values = [];
      snapshot.forEach((child) => {
        values.push(child.val());
      });

      if (values) {
        const check = values.filter(
          (item) => item.username == username && item.password == password
        );
        if (check.length > 0) {
          props.history.push({
            pathname: "/PoDashboard",
            state: { detail: check[0].po_number },
          });
        } else {
          setAlert(true);
        }
      }
    });
  };

  const handleClose = () => {
    setUsername("");
    setPassword("");
    dispatch(clearLoginError());
  };

  return (
    <div style={{ display:'flex',  height:'100vh', backgroundImage:`url(${bg})`, backgroundPosition:'center', backgroundSize:'cover' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <img
            style={{ width: 150, height: 150, marginBottom: 100 }}
            src={logo}
            alt="Logo"
          /> */}
          <Typography component="h1" variant="h5" style={{ color: "#ffffff",marginTop:250 }}>
            PO LOGIN
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.root}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={"Username"}
                name="username"
                autoComplete="username"
                onChange={handleEmailChange}
                value={username}
                autoFocus
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={languageJson.password}
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="current-password"
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>

            <Button
              // type="submit"
              onClick={() => handleSubmit()}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {languageJson.sign_in}
            </Button>
          </form>
        </div>

        <AlertDialog open={checkAlert} onClose={() => setAlert(false)}>
          {"Invalid Username & Password."}
        </AlertDialog>
      </Container>
    </div>
  );
};

export default PoLogin;
